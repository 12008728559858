


















import { Vue, Component } from "vue-property-decorator";
import dashboardRoutes from "@/router/dashboard";

@Component
export default class Index extends Vue {
  private routes = dashboardRoutes;
}
